<template>
    <div>
        <div class="icons-toggle">
            <button @click="toggleButton('special')"><img src="assets/images/icons/pi.svg" alt=""/></button
            >
            <button @click="toggleButton('normal')"><img src="assets/images/icons/om.svg" alt=""/>
            </button>
            <div class="icon-image-file">
                <div class="block-icon">
                    <img src="@/assets/images/icons/upload_image.svg" alt="" height="30" width="30"/>
                    <input type="file" accept="image/png, image/jpeg"
                           class="image-input" @change="toggleImage" ref="imageUploader" @click="resetImageUploader">
                </div>
            </div>
        </div>
        <div v-if="isShowFormula"
             style="border: 1px solid #c4c4c4; padding: 20px; border-radius: 10px;margin-bottom: 15px">
            <div class="formula-box">
                <div class="formula-preview card-border">
                    <p class="formula-preview-title">Xem trước</p>
                    <p ref="mathJaxEl">{{ formula }}</p>
                </div>
            </div>
            <div class="virtual-keyboard-container" v-if="buttonType === 'special'">
                <math-button class="math-button-wrapper"
                             v-for="(item,index) in buttons" :key="index"
                             :icon_src="item.icon_src"
                             :icon_type="item.type"
                             @click="getButtonValue(item.value)"
                />
            </div>
            <div class="virtual-keyboard-container" v-if="buttonType === 'normal'">
                <normal-math-button class="math-button-wrapper"
                                    v-for="(item,index) in buttons_normal" :key="index"
                                    :character="item.character"
                                    @click="getNormalButtonValue(item.character)"
                />
            </div>
            <textarea placeholder="Chỉnh sửa công thức..." class="formula-textarea card-border" v-model="formula"
                      cols="30"
                      rows="3"></textarea>
            <v-btn @click="addFormula" style="margin-top: 10px; background-color: #5867dd;color: white">Thêm công thức
            </v-btn>
        </div>
        <div v-if="list_image" class="display-image">
            <div class="display-image__property">
                <img :src="list_image" width="200" height="200" :alt="list_image">
                <button @click="deleteImage()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FF7F00" class="bi bi-x-circle"
                         viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path
                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import MathButton from "@/components/math/MathButton";
import NormalMathButton from "@/components/math/NormalMathButton";

export default {
    props: {
        formula_prop: {
            type: String
        },
        safe: {
            type: Boolean,
            default: true
        },
        options: {
            type: Object,
            default: function () {
                return {}
            }
        },
        image_prop: {
            type: String
        }
    },
    components: {
        MathButton,
        NormalMathButton
    },
    data() {
        return {
            formula: "",
            buttonType: "",
            list_image: '',
            image: null,
            isShowFormula: false,
            buttons: [
                {
                    icon_src: "assets/icons/math/is-less-than-or-equal-to-mathematical-symbol.svg",
                    value: "$\\leq$"
                }, {
                    icon_src: "assets/icons/math/is-equal-to-or-greater-than-symbol.svg",
                    value: "$\\geq$ "
                }, {
                    icon_src: "assets/icons/math/square-root-mathematical-symbol.svg",
                    value: "$\\sqrt[n]{x}$ "
                }, {
                    icon_src: "assets/icons/math/is-not-equal-to-mathematical-symbol.svg",
                    value: "$\\neq$"
                }, {
                    icon_src: "assets/icons/math/pi-mathematical-constant-symbol.svg",
                    value: "$\\pi$"
                }, {
                    icon_src: "assets/icons/math/integral-mathematical-sign.svg",
                    value: "$\\int\\limits^a_b {x} \\, dx$ ",
                    type: "medium"
                }, {
                    icon_src: "assets/icons/math/limit.svg",
                    value: " $\\lim_{n \\to \\infty} a_n$",
                    type: 'big'
                }, {
                    icon_src: "assets/icons/math/1.svg",
                    value: "$x^{2}$ "
                }, {
                    icon_src: "assets/icons/math/alpha.svg",
                    value: "$\\alpha$ "
                }, {
                    icon_src: "assets/icons/math/fraction.svg",
                    value: "$\\frac{x}{y}$ "
                },
                {
                    icon_src: "assets/icons/math/somol.svg",
                    value: "$x_{123}$ "
                }, {
                    icon_src: "assets/icons/math/22.svg",
                    value: "$\\left \\{ {{y=2} \\atop {x=2}} \\right.$ "
                }, {
                    icon_src: "assets/icons/math/014-matrix.svg",
                    value: "$\\left[\\begin{array}{ccc}1&2&3\\\\4&5&6\\\\7&8&9\\end{array}\\right]$ ",
                    type: "medium"
                },
            ],
            buttons_normal: [
                {
                    'character': '²'
                }, {
                    'character': '³'
                }, {
                    'character': '√'
                }, {
                    'character': '∛'
                }, {
                    'character': '·'
                }, {
                    'character': '×'
                }, {
                    'character': '÷'
                }, {
                    'character': '±'
                }, {
                    'character': '≈'
                }, {
                    'character': '≤'
                }, {
                    'character': '≥'
                }, {
                    'character': '∈'
                }, {
                    'character': '∉'
                }, {
                    'character': '∧'
                }, {
                    'character': '∨'
                }, {
                    'character': '∞'
                }, {
                    'character': 'Δ'
                }, {
                    'character': 'π'
                }, {
                    'character': 'Ф'
                }, {
                    'character': 'ω'
                }, {
                    'character': '↑'
                }, {
                    'character': '↓'
                }, {
                    'character': '∵'
                }, {
                    'character': '∴'
                }, {
                    'character': '↔'
                }, {
                    'character': '→'
                }, {
                    'character': '←'
                }, {
                    'character': '⇵'
                }, {
                    'character': '⇅'
                }, {
                    'character': '⇄'
                }, {
                    'character': '⇆'
                }, {
                    'character': '∫'
                }, {
                    'character': '∑'
                }, {
                    'character': '⊂'
                }, {
                    'character': '⊃'
                }, {
                    'character': '⊆'
                }, {
                    'character': '⊇'
                }, {
                    'character': '⊅'
                }, {
                    'character': '∀'
                }, {
                    'character': '∠'
                }, {
                    'character': '∡'
                }, {
                    'character': '⊥'
                }, {
                    'character': '∪'
                }, {
                    'character': '∩'
                }, {
                    'character': '∅'
                }, {
                    'character': '¬'
                }, {
                    'character': '⊕'
                }, {
                    'character': '║'
                }, {
                    'character': '∦'
                }, {
                    'character': '∝'
                }, {
                    'character': '㏒'
                }, {
                    'character': '㏑'
                },
            ]
        }
    },
    watch: {
        formula_prop: {
            immediate: true,
            handler(newVal) {
                if (!this.formula) {
                    this.formula = "";
                } else {
                    this.formula = newVal
                }
            }
        },
        formula() {
            this.renderMathJax()
        },
        image: {
            immediate: true,
            deep: true,
            handler(newValue) {
                if (newValue) {
                    this.$emit('image', newValue)
                }
            }
        },
        image_prop: {
            immediate: true,
            deep: true,
            handler(newValue) {
                if (newValue && !this.image) {
                    this.list_image = null
                    this.list_image = newValue
                }
            }
        }
    },
    async mounted() {
        await this.addScript("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=TeX-AMS_HTML");
        await this.renderMathJax()
    },
    methods: {
        toggleImage(e) {
            this.image = e.target.files[0]
            this.list_image = URL.createObjectURL(this.image)
        },
        resetImageUploader() {
            this.isShowFormula = false
            this.$refs.imageUploader.value = '';
        },
        deleteImage() {
            this.list_image = ''
            this.image = null
            this.$emit('delImage')
        },
        addScript(url) {
            var script = document.createElement("script");
            script.type = "application/javascript";
            script.src = url;
            document.head.appendChild(script);
        },
        addFormula() {
            this.isShowFormula = false;
            this.$emit('addFormula', this.formula)
            this.formula = ''
        },
        toggleButton(type) {
            if (this.buttonType === type) {
                this.isShowFormula = !this.isShowFormula
            } else {
                this.isShowFormula = true;
                this.buttonType = type;
            }
        },
        getButtonValue(value) {
            this.formula += value;
        },
        getNormalButtonValue(value) {
            this.formula += value;
        },
        renderContent() {
            if (!this.$refs.mathJaxEl) {
                return null;
            }
            if (this.safe) {
                this.$refs.mathJaxEl.textContent = this.formula
            } else {
                this.$refs.mathJaxEl.innerHTML = this.formula
            }
        },
        renderMathJax() {
            this.renderContent()
            if (window.MathJax) {
                window.MathJax.Hub.Config({
                    tex2jax: {
                        inlineMath: [['$', '$'], ['\\(', '\\)']],
                        displayMath: [['$$', '$$'], ['[', ']']],
                        processEscapes: true,
                        processEnvironments: true
                    },
                    // Center justify equations in code and markdown cells. Elsewhere
                    // we use CSS to left justify single line equations in code cells.
                    displayAlign: 'center',
                    'HTML-CSS': {
                        styles: {'.MathJax_Display': {margin: 0}},
                        linebreaks: {automatic: true}
                    },
                    ...this.options
                })
                window.MathJax.Hub.Queue([
                    'Typeset',
                    window.MathJax.Hub,
                    this.$refs.mathJaxEl
                ])
            }
        }
    },
}
</script>

<style lang="scss">
.math-button-wrapper {
  //display: inline-block;
  margin: 5px 5px 5px 0;
}

.formula-textarea {
  padding: 10px;
  font-size: 18px;

}

.formula-preview {
  padding: 10px;
  font-size: 18px;
  min-height: 100px;
}

.formula-box {
  color: black;
}

.formula-preview:empty:before {
  content: attr(data-placeholder);
  color: gray
}

.formula-preview-title {
  text-decoration: underline;
  font-size: 16px;
}

.virtual-keyboard-container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0px;
}

textarea {
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;
  resize: none;
  width: 100%;
}

.card-border {
  border: .5px solid #a6a6a6;
}

.icons-toggle {
  padding: 5px 0px;
  display: flex;
  cursor: grab;

  button {
    margin-right: 15px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.icon-image-file {
  display: flex;
  align-items: center;

  .block-icon {
    position: relative;
    cursor: pointer;

    input {
      top: 0;
      left: 0;
      position: absolute;
      opacity: 0;
    }

    .file-input {
      width: 30px;
      cursor: pointer;
    }

    .image-input {
      width: 30px;
      cursor: pointer;
    }
  }
}

.display-image {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;

  .display-image__property {
    position: relative;
    border: 1px solid #ccc;
    padding: 5px;

    img {
      object-fit: contain;
    }

    button {
      border-radius: 50%;
      position: absolute;
      top: -0.25rem;
      right: 0.25rem;
    }
  }
}
</style>
