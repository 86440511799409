<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Slug </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="detailAsk.slug"
            @input="changeSlug"
            v-model="detailAsk.slug"
        ></hnr-input>
        <span class="validate-error text-danger ">
            {{ slug_message }}
        </span>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Nội dung </span>
      </v-col>
      <v-col cols="12" class="pa-1">
        <ValidationProvider
            name="content"
            rules="required"
            v-slot="{ errors }"
        >
          <div style="border: 1px solid #c4c4c4; padding: 10px;margin-bottom: 20px;height: 100px">
            <math-jax-preview :formula="detailAsk.content"></math-jax-preview>
          </div>
          <ck-content
              :content="detailAsk.content"
              @getData="updateContent"
              v-model="detailAsk.content"
          ></ck-content>
          <math-jax-editor
              :formula_prop="formula"
              @addFormula="addFormula"
              :image_prop="detailAsk.image"
              @image="detailAsk.image = $event"
              @delImage="delImage"
          ></math-jax-editor>
          <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
        </ValidationProvider>

      </v-col>
    </v-row>
    <v-row v-if="!isNew" class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Đăng bởi </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <span v-if="detailAsk.creatable.email">{{ detailAsk.creatable.email }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from "@/components/elements/hnr-input";
import MathJaxEditor from "@/components/math/MathJaxEditor";
import CkContent from "@/components/ck-content";
import MathJaxPreview from "@/components/math/MathJaxPreview.vue";
import mixin from "@/store/mixin";

export default {
  mixins: [mixin],
  data() {
    return {
      title: "",
      content: '',
      formula: "",
      dialog: false,
      detailAsk: {},
      selected: [],
      value: 0,
      search: null,
      singleSelect: true,
      suggestNewCategories: [],
      headers: [
        {
          text: "id",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
      ],
    };
  },
  props: {
    ask: Object,
    isNew: {type: Boolean, default: false},
    slug_message: {type: String},
  },
  watch: {
    ask: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.detailAsk = newVal;

        }
      },
    },
    detailAsk: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (this.isNew) {
          if (!this.detailAsk.has_change_slug) {
            let removeHtml = newVal.content.replace(/<[^>]*>?/gm, '')
            this.detailAsk.slug = this.sanitizeTitle(removeHtml);
          }
        }
      },
    },
  },
  components: {
    HnrInput,
    MathJaxEditor,
    CkContent,
    MathJaxPreview,
  },
  methods: {
    delImage() {
      this.detailAsk.image = ''
      this.$emit('delImage', true)
    },
    changeSlug() {
      this.detailAsk.has_change_slug = true;
    },
    addFormula(value) {
      this.detailAsk.content += value;
    },
    updateContent(val) {
      this.detailAsk.content = val;
    },
    renderMathJax() {
      if (window.MathJax) {
        window.MathJax.Hub.Config({
          tex2jax: {
            inlineMath: [['$', '$'], ['\\(', '\\)']],
            displayMath: [['$$', '$$'], ['[', ']']],
            processEscapes: true,
            processEnvironments: true
          },
          // Center justify equations in code and markdown cells. Elsewhere
          // we use CSS to left justify single line equations in code cells.
          displayAlign: 'center',
          'HTML-CSS': {
            styles: {'.MathJax_Display': {margin: 0}},
            linebreaks: {automatic: true}
          },
          ...this.options
        })
        window.MathJax.Hub.Queue([
          'Typeset',
          window.MathJax.Hub,
          this.$refs.mathJaxEl
        ])
      }
    }
  }
  ,
}
;
</script>

<style lang="scss">
</style>
