<template>
    <v-container>
        <ValidationObserver v-slot="{ handleSubmit }">
            <v-tabs v-model="tabIndex">
                <v-tab v-for="item in items" :key="item.id" :disabled="isCreate && item.id == 2 ? true : false">{{
                    item.tab
                    }}
                </v-tab>
            </v-tabs>
            <v-tabs-items class="tab-content " v-model="tabIndex">
                <v-tab-item active>
                    <t-daily-question-content class="tab-detail"
                                              :ask="detailAsk"
                                              :isNew="isCreate"
                                              @delImage="delImage = $event">
                    </t-daily-question-content>
                </v-tab-item>
                <v-tab-item>
                    <t-daily-question-seo class="tab-detail" :ask="detailAsk"></t-daily-question-seo>
                </v-tab-item>
            </v-tabs-items>
            <v-row v-if="isCreate" class="align-center justify-center">
                <v-btn color="primary" @click="handleSubmit(saveLesson)">Lưu câu hỏi</v-btn>
            </v-row>
            <v-row v-else class="align-center justify-center">
                <v-btn color="primary" @click="handleSubmit(updateLesson)">Cập nhật câu hỏi</v-btn>
            </v-row>
            <v-dialog
                    v-model="dialog"
                    max-width="600px"
                    min-height="600px"
            >
            </v-dialog>
        </ValidationObserver>
    </v-container>
</template>
<script>
import {mapGetters} from "vuex";

// stores
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {SET_LOADING} from "@/store/loading.module";
import {SAVE_ASK, UPDATE_ASK, DETAIL_ASK} from "@/store/ask.module";
import {SUCCESS, ERROR} from "@/store/alert.module";
// components
import TDailyQuestionContent from "@/components/dailyQuestion/TDailyQuestionContent.vue";
import TDailyQuestionSeo from "@/components/dailyQuestion/TDailyQuestionSeo.vue";
import {DELETE_CATEGORY_LESSON} from "@/store/lesson.module";
// import HnrInput from '@/components/elements/hnr-input'
// import HnrEditTable from '@/components/elements/hnr-edit-table'

export default {
    data() {
        return {
            detailAsk: {
                slug: "",
                content: "",
                seo_title: "",
                seo_description: "",
                seo_key: "",
                image: ''
            },
            slug: null,
            isCreate: false,
            isRedirect: true,
            tabIndex: 0,
            items: [
                {id: 0, tab: "Thông tin"},
                {id: 1, tab: "Cấu hình SEO"},
            ],
            categories: [],
            dialog: false,
            value: 0,
            search: null,
            singleSelect: true,
            selected: [],
            headers: [
                {
                    text: 'id',
                    align: 'start',
                    sortable: true,
                    value: 'id',
                },
                {
                    text: 'Name',
                    align: 'start',
                    sortable: true,
                    value: 'name'
                }
            ],
            delImage: false
        };
    },
    components: {
        // HnrInput,
        TDailyQuestionContent,
        TDailyQuestionSeo,
        // HnrEditTable
    },
    computed: {
        ...mapGetters({
            ask: "detailAsk",
        }),
    },
    watch: {
        ask: {
            deep: true,
            handler(newVal) {
                this.detailAsk = newVal;
            },
        },
    },
    created() {
        this.slug = this.$route.params.slug;
        if (this.slug) {
            this.isCreate = false;
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.slug}]);
            this.$store.dispatch(SET_LOADING, true);
            this.$store.dispatch(DETAIL_ASK, this.slug).then(() => {
                this.$store.dispatch(SET_LOADING, false);
            }).catch(() => {
                this.$store.dispatch(SET_LOADING, false);
            });
        } else {
            this.isCreate = true;
            this.$store.dispatch(SET_BREADCRUMB, [{title: "Thêm câu hỏi"}]);
        }
    },

    methods: {
        regexImageUrl(content) {
            if (!content) {
                return null;
            }
            content = content.replace(
                /<img([\w\W]+?)>/gi,
                function (match) {
                    match = match.replace(
                        /src\s*=\s*"/g,
                        function () {
                            return 'src="' + process.env.VUE_APP_BASE_URL + "/";
                        }
                    );
                    return match;
                }
            );
            return content;
        },

        saveLesson() {
            this.isRedirect = false;
            let form = new FormData()
            if (this.detailAsk.image) {
                form.append('image', this.detailAsk.image)
            }
            form.append('content', this.detailAsk.content)
            form.append('slug', this.detailAsk.slug)
            form.append('seo_key', this.detailAsk.seo_key)
            form.append('seo_title', this.detailAsk.seo_title)
            form.append('seo_description', this.detailAsk.seo_description)
            this.$store.dispatch(SET_LOADING, true);
            this.$store.dispatch(SAVE_ASK, form).then(data => {
                if (data.status) {
                    this.$store.dispatch(SUCCESS, "Thêm câu hỏi thành công!", {root: true});
                    this.$router.push('/dailyQuestion');
                }
                this.$store.dispatch(SET_LOADING, false);
            }).catch((err) => {
                if (err.status == 422 && err.data.errors?.seo_title) {
                    this.$store.dispatch(ERROR, "Chưa điền SEO !", {root: true});
                } else {
                    this.$store.dispatch(ERROR, "Thêm câu hỏi thất bại!", {root: true});

                }
                this.$store.dispatch(SET_LOADING, false);
            });
        },

        updateLesson() {
            this.isRedirect = false;
            this.$store.dispatch(SET_LOADING, true);

            let formData = null
            if (typeof this.detailAsk.image === 'object') {
                formData = new FormData()
                formData.append('content', this.detailAsk.content)
                formData.append('image', this.detailAsk.image)
                formData.append('seo_key', this.detailAsk.seo_key)
                formData.append('seo_title', this.detailAsk.seo_title)
                formData.append('seo_description', this.detailAsk.seo_description)
                formData.append('_method', "PATCH")
            } else if (this.delImage) {
                formData = {
                    image: null,
                    content: this.detailAsk.content,
                    seo_key: this.detailAsk.seo_key,
                    seo_title: this.detailAsk.seo_title,
                    seo_description: this.detailAsk.seo_description,
                    _method: "PATCH",
                }
            } else {
                formData = {
                    content: this.detailAsk.content,
                    seo_key: this.detailAsk.seo_key,
                    seo_title: this.detailAsk.seo_title,
                    seo_description: this.detailAsk.seo_description,
                    _method: "PATCH",
                }
            }
            let payload = {
                id: this.detailAsk.id,
                data: formData
            }
            this.$store.dispatch(UPDATE_ASK, payload).then(data => {
                if (data.status) {
                    this.$store.dispatch(SUCCESS, "Cập nhật bài viết thành công!", {root: true});
                    this.$router.push('/dailyQuestion');
                } else {
                    if (data.errorCode == 424) {
                        this.$store.dispatch(ERROR, data.message, {
                            root: true,
                        });
                    } else {
                        this.$store.dispatch(ERROR, "Cập nhật bài viết thất bại!", {
                            root: true,
                        });
                    }
                }
                this.$store.dispatch(SET_LOADING, false);
            }).catch(() => {
                this.$store.dispatch(ERROR, "Cập nhật bài viết thất bại!", {root: true});
                this.$store.dispatch(SET_LOADING, false);
            });
        },
        updateImageUrl(content) {
            if (!content) {
                return null;
            }
            content = content.replace(
                /<img([\w\W]+?)>/gi,
                function (match) {
                    match = match.replace(
                        /src="([^"]+)"/g,
                        function (match_child) {
                            let base_url = process.env.VUE_APP_BASE_URL
                            var regex = new RegExp(base_url + "/(.*)", "");
                            let relative_path = "";
                            let relative_path_arr = match_child.match(regex)
                            if (relative_path_arr.length > 0) {
                                relative_path = relative_path_arr[1];
                                return 'src="' + relative_path;
                            }
                        }
                    );
                    return match;
                }
            );
            return content;
        },
    },
    beforeRouteLeave(to, from, next) {
        if (this.isRedirect) {
            if (confirm("Bạn chưa lưu bài viết.Bạn vẫn muốn rời đi chứ?")) {
                next()
            } else {
                next(false)
            }
        } else {
            next();
        }
    },
};
</script>


<style lang="scss">
.tab-detail {
  margin-top: 15px;
}
</style>
